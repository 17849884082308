<template>
  <div style="width: 100%;max-width: 568px;" class="body">

    <div style="width: 100%;max-width: 568px;" id="app">
      <section ref="topHeader"
        style="top: 0;width: 100%;max-width: 568px;z-index: 1000;background-color: var(--darkBackground);"
        class="top-header" v-if="current_page != 'print-game'" v-show="current_page != 'casinogames'">

        <router-link v-if="!$route.meta.hideMenu" to="/deposit" class="deposit-link"
          v-show="current_page != 'deposit' && current_page != 'casinogames'">
          <div class="row deposit">
            Click for FREE DEPOSIT -> MPESA PAYBILL: <strong>575758</strong>
          </div>
        </router-link>

        <div v-if="!$route.meta.hideMenu" class="d-flex m-0 align-items-center header"
          style="background-color: var(--darkBackground); "
          v-show="current_page != 'deposit' && current_page != 'casinogames'">
          <div class="d-flex align-items-center px-0 pt-1 pb-1 m-0 text-center logo">
            <a class="" @click="showMenu">
              <img style="width: 19px;" src="/img/icon/icons-menu.png" />
            </a>

            <router-link to="/">
              <img style="float: left;width: 140px;" src="/img/TuchezeLogo.svg" class="top-header-logo img-fluid"
                alt="Tucheze.com Logo">
            </router-link>
          </div>



          <div v-if="!$route.meta.hideMenu" class="p-0 login">

            <div v-if="profile" class=" d-flex depo text-center justify-content-around ">
              <router-link to="/my-account">
                <img src="/img/icon/new_wallet.png" class="wallet-icon"> <br>
                <span class="wallet-balance text-yellow wallet-text">
                  <strong>Ksh<span v-text="bal"></span></strong>
                </span>
              </router-link>
              <router-link class="" to="/search">
                <img style="width: 19px;margin-top: 7px;margin-left: 9px;" src="/img/icon/icons-search.png" />
              </router-link>
            </div>

            <div v-else class="d-flex text-center d-none">
              <router-link to="/login" class="header-login-button small-text mr-1">
                Login
              </router-link>

              <router-link to="/join" class="header-join-button small-text" style="color: #000 !important;">
                Join
              </router-link>

              <router-link class="" to="/search">
                <img style="width: 19px;margin-top: 5px;margin-left: 7px;" src="/img/icon/icons-search.png" />
              </router-link>
            </div>
          </div>
        </div>

      </section>

      <section v-if="!$route.meta.hideMenu" style="position: relative;z-index: 500;" class="feature-menu p0 pb-3">

        <div class="icons-wrapper top-menu-items d-flex justify-content-between">

          <div style="margin-right: -15px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('sport')">
            <router-link to="/">
              <div class="icon-container">
                <img src="/img/TopNav/Soccer.svg" class="menu-icon-sports-svg">
              </div>
              <span class="text-blue txt">Football</span>
            </router-link>
          </div>

          <div style="margin-right: -15px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('home')">
            <router-link to="/crash">
              <div class="icon-container">
                <img style="fill: red;" src="/img/TopNav/Growth.svg" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Crash</span>
            </router-link>
          </div>

          <div style="margin-left: 0px;" class="feature-icon-item-wrapper text-center "
            v-bind:class="isActive('casino')">
            <router-link to="/casino">
              <div class="icon-container">
                <img src="/img/TopNav/Chip.svg" class="menu-icon-svg">
                <sup><span class="custom-badge">NEW</span></sup>
              </div>
              <span class="text-blue txt">Casino</span>
            </router-link>
          </div>

          <div style="margin-left: -15px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('gifts')">
            <router-link to="/gifts">
              <div class="icon-container">
                <img src="/img/TopNav/Discount.svg" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Promos</span>
            </router-link>
          </div>

          <div style="margin-left: -21px;" class="feature-icon-item-wrapper text-center">
            <router-link to="/leaderboard">
              <div class="icon-container">
                <img src="/menu/leaderboard.png" class="menu-icon-svg">
              </div>
              <span class="text-blue txt">Leaderboard</span>
            </router-link>
          </div>

          <div style="margin-left: -20px;" class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('mobile-app')">
            <router-link to="/mobile-app">
              <div class="icon-container">
                <img src="/img/TopNav/Smartphone.svg" class="menu-icon-svg"> <sup><span
                    class="custom-badge">NEW</span></sup>
              </div>
              <span class="text-blue txt"> App</span>
            </router-link>
          </div>

          <div style="margin-left: -14px;" class="feature-icon-item-wrapper text-center" @click="toggleOverlay">
            <div class="icon-container-two">
              <img style="width: 170px;" src="/img/TopNav/side-menu.svg" class="menu-icon-sports-svg">
            </div>
            <span class="text-blue txt">
              <strong>MORE</strong>
            </span>
          </div>
        </div>
      </section>

      <transition name="fade">
        <div v-if="showOverlay" class="mainoverlay">
          <div class="overlay-content">
            <section ref="topHeader"
              style="top: 0;width: 100%;max-width: 568px;z-index: 1000;background-color: var(--darkBackground);"
              class="top-header" v-if="current_page != 'print-game'" v-show="current_page != 'casinogames'">
              <div @click="toggleOverlay">
                <router-link v-if="!$route.meta.hideMenu" to="/deposit" class="deposit-link"
                  v-show="current_page != 'deposit' && current_page != 'casinogames'">
                  <div class="row deposit">
                    Click for FREE DEPOSIT -> MPESA PAYBILL: <strong>575758</strong>
                  </div>
                </router-link>
              </div>

              <div @click="toggleOverlay" v-if="!$route.meta.hideMenu" class="d-flex m-0 align-items-center header"
                style="background-color: var(--darkBackground); "
                v-show="current_page != 'deposit' && current_page != 'casinogames'">
                <div class="d-flex align-items-center px-0 pt-1 pb-1 m-0 ml-3 text-center logo">
                  <a class="" @click="showMenu">
                    <img style="width: 19px;" src="/img/icon/icons-menu.png" />
                  </a>

                  <router-link to="/">
                    <img style="float: left;width: 140px;" src="/img/TuchezeLogo.svg" class="top-header-logo img-fluid"
                      alt="Tucheze.com Logo">
                  </router-link>
                </div>



                <div v-if="!$route.meta.hideMenu" class="p-0 login">

                  <div v-if="profile" class=" d-flex depo text-center justify-content-around mr-4">
                    <router-link to="/my-account">
                      <img src="/img/icon/new_wallet.png" class="wallet-icon"> <br>
                      <span class="wallet-balance text-yellow wallet-text">
                        <strong>Ksh<span v-text="bal"></span></strong>
                      </span>
                    </router-link>
                    <router-link class="" to="/search">
                      <img style="width: 19px;margin-top: 7px;margin-left: 9px;" src="/img/icon/icons-search.png" />
                    </router-link>
                  </div>

                  <div v-else class="d-flex text-center d-none">
                    <router-link to="/login" class="header-login-button small-text mr-1">
                      Login
                    </router-link>

                    <router-link to="/join" class="header-join-button small-text" style="color: #000 !important;">
                      Join
                    </router-link>

                    <router-link class="" to="/search">
                      <img style="width: 19px;margin-top: 5px;margin-left: 7px;" src="/img/icon/icons-search.png" />
                    </router-link>
                  </div>
                </div>
              </div>

            </section>

            <section style="position: relative;z-index: 500;" class="feature-menu p0 pb-3">

              <div class="icons-wrapper top-menu-items d-flex justify-content-between">

                <div style="margin-right: -15px;margin-left: 15px;" class="feature-icon-item-wrapper text-center"
                  v-bind:class="isActive('sport')" @click="toggleOverlay">
                  <router-link to="/">
                    <div class="icon-container">
                      <img src="/img/TopNav/Soccer.svg" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Football</span>
                  </router-link>
                </div>

                <div style="margin-right: -15px;" class="feature-icon-item-wrapper text-center"
                  v-bind:class="isActive('home')" @click="toggleOverlay">
                  <router-link to="/crash">
                    <div class="icon-container">
                      <img style="fill: red;" src="/img/TopNav/Growth.svg" class="menu-icon-svg">
                    </div>
                    <span class="text-blue txt">Crash</span>
                  </router-link>
                </div>

                <div style="margin-left: 3px;" class="feature-icon-item-wrapper text-center "
                  v-bind:class="isActive('casino')" @click="toggleOverlay">
                  <router-link to="/casino">
                    <div class="icon-container">
                      <img src="/img/TopNav/Chip.svg" class="menu-icon-svg">
                      <sup><span class="custom-badge">NEW</span></sup>
                    </div>
                    <span class="text-blue txt">Casino</span>
                  </router-link>
                </div>

                <div style="margin-left: -14px;" class="feature-icon-item-wrapper text-center"
                  v-bind:class="isActive('gifts')" @click="toggleOverlay">
                  <router-link to="/gifts">
                    <div class="icon-container">
                      <img src="/img/TopNav/Discount.svg" class="menu-icon-svg">
                    </div>
                    <span class="text-blue txt">Promos</span>
                  </router-link>
                </div>


                <div style="margin-left: -21px;" class="feature-icon-item-wrapper text-center" @click="toggleOverlay">
                  <router-link to="/leaderboard">
                    <div class="icon-container">
                      <img src="/menu/leaderboard.png" class="menu-icon-svg">
                    </div>
                    <span class="text-blue txt">Leaderboard</span>
                  </router-link>
                </div>

                <div style="margin-left: -20px;" class="feature-icon-item-wrapper text-center"
                  v-bind:class="isActive('mobile-app')" @click="toggleOverlay">
                  <router-link to="/mobile-app">
                    <div class="icon-container">
                      <img src="/img/TopNav/Smartphone.svg" class="menu-icon-svg"> <sup><span
                          class="custom-badge">NEW</span></sup>
                    </div>
                    <span class="text-blue txt"> App</span>
                  </router-link>
                </div>

                <div style="margin-left: -14px;margin-right: 14px;" class="feature-icon-item-wrapper text-center"
                  @click="toggleOverlay">
                  <div class="icon-container-two">
                    <img src="/img/BottomNav/close.png" alt="Close Overlay" style="width: 20px;">
                  </div>
                  <span class="text-blue txt">
                    <strong>LESS</strong>
                  </span>
                </div>
              </div>
            </section>

            <div style="border-bottom: 0.02rem solid #324257;">
            </div>

            <section style="position: relative;z-index: 500;" class="p0 pb-3">
              <br />
              <h6 style="color: var(--yellow);margin-left: 30px;">Sports</h6>
              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex">
                <div v-for="sport in sportsList" :key="sport.id"
                  class="feature-icon-item-wrapper text-center sport-item">
                  <div @click="setSportMainMenu(sport.id, sport.name)">
                    <div class="icon-container-darker">
                      <img :src="sport.icon" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">{{ sport.displayName }}</span>
                  </div>
                </div>
              </div>
            </section>

            <div style="border-bottom: 0.02rem solid #324257;">
            </div>

            <section style="position: relative;z-index: 500;" class="p0 pb-3">
              <br />
              <h6 style="color: var(--yellow);margin-left: 30px;">Account</h6>

              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex">
                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/my-profile-bets">
                    <div class="icon-container-darker">
                      <img src="/menu/bet_history.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Bet History</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/my-account">
                    <div class="icon-container-darker">
                      <img src="/menu/my_account.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">My Account</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/deposit">
                    <div class="icon-container-darker">
                      <img src="/menu/deposit.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Deposit</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/my-account">
                    <div class="icon-container-darker">
                      <img src="/menu/withdraw.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Withdraw</span>
                  </router-link>
                </div>

              </div>

            </section>

            <div style="border-bottom: 0.02rem solid #324257;">
            </div>

            <section style="position: relative;z-index: 500;" class="p0 pb-3">
              <br />
              <h6 style="color: var(--yellow);margin-left: 30px;">Socials</h6>

              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex">
                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a href="https://www.facebook.com/tuchezecom?mibextid=LQQJ4d">
                    <div class="icon-container-darker">
                      <img src="/menu/facebook.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Facebook</span>
                  </a>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a href="https://x.com/tucheze_com?s=21&t=_ZmI-6RKcSSVGyAF5abuDg">
                    <div class="icon-container-darker">
                      <img src="/menu/x.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">X</span>
                  </a>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a href="https://www.instagram.com/tucheze_com?igsh=OGd6MjJqMXFqaWJ6">
                    <div class="icon-container-darker">
                      <img src="/menu/instagram.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Instagram</span>
                  </a>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a href="https://www.tiktok.com/@tucheze.com?_t=8pZQvDw9pDS&_r=1">
                    <div class="icon-container-darker">
                      <img src="/menu/tiktok.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Tiktok</span>
                  </a>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <a href="https://wa.me/254716797777">
                    <div class="icon-container-darker">
                      <img src="/menu/whatsapp.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">WhatsApp</span>
                  </a>
                </div>

              </div>

            </section>


            <div style="border-bottom: 0.02rem solid #324257;">
            </div>

            <section style="position: relative;z-index: 500;" class="p0 pb-3">
              <br />
              <h6 style="color: var(--yellow);margin-left: 30px;">Help</h6>

              <div @click="toggleOverlay" class="icons-wrapper-menu top-menu-items d-flex">
                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/responsible-gaming">
                    <div class="icon-container-darker">
                      <img src="/img/menu/age-limit.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Responsible</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/faq">
                    <div class="icon-container-darker">
                      <img src="/menu/faq.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">F.A.Q</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/terms">
                    <div class="icon-container-darker">
                      <img src="/menu/terms.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Terms & Conditions</span>
                  </router-link>
                </div>

                <div class="feature-icon-item-wrapper text-center sport-item">
                  <router-link to="/">
                    <div class="icon-container-darker">
                      <img src="/menu/privacy.png" class="menu-icon-sports-svg">
                    </div>
                    <span class="text-blue txt">Privacy Policy</span>
                  </router-link>
                </div>

              </div>

            </section>

          </div>
        </div>
      </transition>

      <!-- <div class="overlay" v-if="showCard">
        <div id="container">

          <div class="dot">
            <img style="margin-right: 5px;margin-bottom: 5px;" class="close-button" @click="hideCardFor24Hours"
              src="/close-light.png" alt="close" />
          </div>
          <a href="/app/tucheze-app-v1.0.1.apk">
            <div id="success-box">
            </div>
          </a>
        </div>
      </div> -->


      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999;">
        <div class="faso-menu-modal-content">
          <section class="w-100 menu-bg">
            <div class="row m-0 p-0 side-menu-header">
              <div>
                <div class="p-3 px-3">
                  <strong class="text-light faso-menu-close" style="cursor: pointer;">
                    <img style="width: 20px;" src="/img/BottomNav/close.png" alt="Tucheze.com close menu">
                  </strong>
                </div>
              </div>
              <div>
                <img style="width: 140px;" src="/img/TuchezeLogo.svg" class="top-header-logo img-fluid"
                  alt="Tucheze.com Logo" @click="goTo('home')">
              </div>
              <div class="text-right">
                <div v-if="profile" class=" d-flex depo text-center justify-content-around ">

                  <router-link to="/my-account" @click.native="closeMenu">
                    <img src="/img/icon/new_wallet.png" class="wallet-icon"> <br>
                    <span class="wallet-balance text-yellow wallet-text">
                      <strong>Ksh<span v-text="bal"></span></strong>
                    </span>
                  </router-link>
                </div>

                <div v-else class="d-flex text-center pr-2 pt-1">
                  <router-link @click.native="closeMenu" to="login" class="header-join-button small-text"
                    style="color: #000 !important;margin-top: 9px;">
                    Login
                  </router-link>
                </div>
              </div>
            </div>

            <hr class="m-1">
            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link @click.native="closeMenu" to="/" class="flat-link">
                <img src="/menu/soccer.png" class="side-icon-svg mr-2">
                <span class="text-blue">Sports</span>
              </router-link>
            </div>
            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link @click.native="closeMenu" to="/live" class="flat-link">
                <img src="/menu/live.png" class="side-icon-svg mr-2">
                <span class="text-blue">Live</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link @click.native="closeMenu" to="/virtuals" class="flat-link">
                <img src="/img/TopNav/Soccer.svg" class="side-icon-svg mr-2">
                <span class="text-blue">Virtuals</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link @click.native="closeMenu" to="/casino" class="flat-link">
                <img src="/menu/casino.png" class="side-icon-svg mr-2">
                <span class="text-blue">Casino</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link @click.native="closeMenu" to="/jackpot" class="flat-link">
                <img src="/menu/jackpot.png" class="side-icon-svg mr-2">
                <span class="text-blue">Jackpots</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/print" class="flat-link" @click.native="closeMenu">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" class="theme-dark mr-2"
                  style="color: #9ba6d3;">
                  <g opacity="0.39">
                    <path d="M4.5 6.75V1.5H13.5V6.75" stroke="white" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                    <path
                      d="M4.5 13.5H3C2.60218 13.5 2.22064 13.342 1.93934 13.0607C1.65804 12.7794 1.5 12.3978 1.5 12V8.25C1.5 7.85218 1.65804 7.47064 1.93934 7.18934C2.22064 6.90804 2.60218 6.75 3 6.75H15C15.3978 6.75 15.7794 6.90804 16.0607 7.18934C16.342 7.47064 16.5 7.85218 16.5 8.25V12C16.5 12.3978 16.342 12.7794 16.0607 13.0607C15.7794 13.342 15.3978 13.5 15 13.5H13.5"
                      stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M13.5 10.5H4.5V16.5H13.5V10.5Z" stroke="white" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                  </g>
                </svg>
                <span class="text-blue faso-menu-close"> Print Odds & Matches </span>
              </router-link>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-light">Player Account</strong>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-profile-bets" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/menu/bet_history.png" class="wallet-icon mr-2">
                  <span class="text-blue">Bet History</span>
                </a>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeMenu" class="flat-link">
                <img src="/menu/my_account.png" class="wallet-icon mr-2">
                <span class="text-blue">My Account</span>
              </router-link>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-light">Help</strong>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="flat-link" to="/responsible-gaming" @click.native="closeMenu">
                <img src="/img/menu/age-limit.png" class="side-icon-svg mr-2">
                <span class="text-blue">Responsible Gaming</span>
              </router-link>

            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="flat-link" to="/faq" @click.native="closeMenu">
                <img src="/menu/faq.png" class="side-icon-svg mr-2">
                <span class="text-blue">Frequently Asked Questions</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="flat-link" to="/terms" @click.native="closeMenu">
                <img src="/menu/terms.png" class="side-icon-svg mr-2">
                <span class="text-blue">Terms and Conditions</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="flat-link" to="/privacy" @click.native="closeMenu">
                <img src="/menu/privacy.png" class="side-icon-svg mr-2">
                <span class="text-blue">Privacy Policy</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <router-link class="flat-link" to="/aml" @click.native="closeMenu">
                <img src="/menu/protect.png" class="side-icon-svg mr-2">
                <span class="text-blue">Anti-money Laundering Policy</span>
              </router-link>
            </div>

            <div style="border-bottom: solid 1px #96a9de;" class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img src="/menu/logout.png" class="side-icon-svg mr-2">
                <span class="text-danger">Logout</span>
              </a>
            </div>
          </section>
          <Footer />

        </div>


      </div>
      <!--End A-Z Menu-->
      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div class="depo-widget d-none" id="dpo-widg" v-show="current_page != 'deposit'">
        <span id="dpo-close" class="text-light" @click="closeWidget()"><i class="bi bi-x-lg"></i></span>
        <router-link to="/deposit" class="deposit-link" v-show="current_page != 'deposit'">
          <img src="/img/depo.webp" class="depo-widg">
        </router-link>
      </div>

      <InstantDeposit />
    </div>

  </div>
</template>

<script>
import mqtt from "mqtt";
import Footer from "./components/Footer.vue";
import store from './store/store';
import walletserve from "@/services/walletserve";
import InstantDeposit from '@/components/wallet/InstantDeposit';
import { pushToDataLayer } from "@/utils/gtm";
// const Sports = () => import('@/components/accordion/SportsMenu.vue')

export default {
  store,
  name: 'app',
  data: function () {
    return {
      promptTxt: '',
      isVisible: false,
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message: 'Please enter amount you want to deposit',
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
      ],
      isWebView: false,
      referralCode: null,
      homePageGame: [
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
      ],
      showOverlay: false,
      selectedSport: null,
      sportsList: [
        { id: 1, name: 'Soccer', displayName: 'Football', icon: '/menu/soccer.png' },
        { id: 2, name: 'Basketball', displayName: 'Basketball', icon: '/menu/basket-ball.png' },
        { id: 21, name: 'Cricket', displayName: 'Cricket', icon: '/menu/cricket.png' },
        { id: 5, name: 'Tennis', displayName: 'Tennis', icon: '/menu/tennis.png' },
        { id: 4, name: 'IceHockey', displayName: 'Ice Hockey', icon: '/menu/IceHockey.png' },
        { id: 16, name: 'AmericanFootball', displayName: 'A. Football', icon: '/menu/american.png' },
        { id: 10, name: 'Boxing', displayName: 'Boxing', icon: '/menu/boxing.png' },
        { id: 31, name: 'Badminton', displayName: 'Badminton', icon: '/menu/badminton.png' },
        { id: 12, name: 'Rugby', displayName: 'Rugby', icon: '/menu/rugby.png' },
        { id: 23, name: 'Volleyball', displayName: 'Volleyball', icon: '/menu/volleyball.png' },
        { id: 6, name: 'Handball', displayName: 'Handball', icon: '/menu/handball.png' },
        { id: 117, name: 'MixedMartialArts', displayName: 'M.M.A', icon: '/menu/Mma.png' },
        { id: 22, name: 'Darts', displayName: 'Darts', icon: '/menu/Darts.png' }
      ],
    }
  },
  components: {
    Footer,
    InstantDeposit,
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    this.checkIfWebView();

    this.initMenuModal();
    this.getSports();
    setInterval(function () {

      vm.myProfile = vm.getProfile();

    }, 2000);

    this.getTopHeaderHeight();

    this.EventBus.$on('init:mqtt', function () {

      console.log('Wants Inititalize MQTT');

      if (vm.mqttClient !== false) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referral_code = params.code ? params.code : ''
    var referrer = document.referrer
    var btag = params.btag ? params.btag : ''
    console.log('GOT referrer ' + referrer)

    if (btag.length > 0) {
      this.setValue('btag', btag)
    }

    if (utm_source.length > 0) {

      this.setValue('utm_source', utm_source)
    }

    if (utm_medium.length > 0) {

      this.setValue('utm_medium', utm_medium)
    }

    if (referral_code.length > 0) {

      this.setValue('referral_code', referral_code)
    }

    if (utm_campaign.length > 0) {

      this.setValue('utm_campaign', utm_campaign)
    }
    this.setValue('referrer', referrer)

    if (this.$refs.topHeader) {
      window.addEventListener('resize', this.getTopHeaderHeight);
    }
  },

  unmounted: function () {
    window.removeEventListener('resize', this.getTopHeaderHeight);
  },

  computed: {
    showCard() {
      return this.$store.state.showCard;
    },
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return '';
      } else {
        return 'd-none';
      }
    },
    bal: function () {

      return this.formatCurrency(this.$store.state.balance);

    },
    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },
    profile: function () {

      return this.myProfile;

    },
    current_page: function () {

      return this.$store.state.current_page;

    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },
    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if (!p) {

        return 0;

      }

      return p.b

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {
    setSportMainMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.selectedSport = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    hideCardFor24Hours() {
      this.$store.commit('hideCardFor24Hours');
    },
    neverShowCardAgain() {
      this.$store.commit('neverShowCardAgain');
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },

    onClose() {
      this.amount = null;
      this.message = null;
      this.loading = false;
      this.isVisible = false;
      this.promptTxt = "";
    },
    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
    },
    handleDepositClick(event) {
      this.deposit(event);
    },
    trackDepositButtonClick(event) {
      pushToDataLayer('gtm.deposit', {
        category: 'Button',
        action: 'Click',
        label: 'Deposit',
        element: event.target
      });
    },
    deposit: function (event) {
      this.loading = false
      this.message = null
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.message = {
          type: "error",
          message: "Invalid Amount. Enter amount atleast 10 KSH or above"
        };
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      this.loading = true;

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          this.trackDepositButtonClick(event);
          var msg = res.data.data;
          this.message = {
            message: msg,
            type: 'success'
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              this.message = {
                message: err.response.data.error_message ?? 'Failed',
                type: 'error'
              }
            }
          } else if (err.request) {
            this.message = {
              message: "Check your network connection and try again",
              type: 'error'
            }
          } else {
            this.message = {
              message: "An error occurred. Please try again",
              type: 'error'
            }
            console.log(JSON.stringify(err));
          }
        }).finally(() => {
          this.loading = false
        });
    },

    checkIfWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
        (/\bwv\b/.test(userAgent)) ||
        (/Android.*Version\/[\d.]+.*(?:Chrome\/[\d.]+ Mobile Safari\/[\d.]+|wv)/i.test(userAgent));

      this.isWebView = isWebView;

      if (isWebView) {
        console.log("User is using a WebView.");
      } else {
        console.log("User is using a standard browser.");
      }
    },

    getReferrer() {
      return document.referrer || "No referrer found";
    },

    canStick: function () {
      if (this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'header-stick';
      }
    },
    canFeatureStick: function () {
      if (this.$store.state.current_page == 'match' || this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'casino' || this.$store.state.current_page == 'casino-live' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'feature-stick';
      }
    },
    isActive: function (page) {

      return this.current_page === page ? 'active' : '';

    },


    setActive: function (menu) {

      this.current_menu = menu;

    },
    livescore: function () {


    },

    initMqtt: function () {
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      // connection option
      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      console.log('at endpoint ' + endpoint);

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {
        console.log('connected here');

        if (vm.myProfile) {
          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {
            if (!err) {
              console.log('subscribed to topic ' + profileTopic);
            }
          });
        }
      });

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {

      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },



    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = '';
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDeposit: function () {

      this.dismiss();
      this.$router.push({ name: 'deposit' });
    },
    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },
    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    },
    getTopHeaderHeight: function () {
      const height = this.$refs.topHeader?.clientHeight;

      //set root css variable
      document.documentElement.style.setProperty('--top-header-height', (height ?? 0) + 'px');


    },
  },
  created() {
    this.$store.dispatch('fetchReferralCode');
    this.$store.commit('checkCardVisibility');
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

html {
  display: grid;
  min-height: 100%;
}

.icons-wrapper-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.sport-item {
  flex: 1;
  min-width: 60px;
  max-width: 90px;
  margin: 4px;
  text-align: center;
}

.icon-container-darker {
  width: 70%;
  display: flex;
  justify-content: center;
}

.menu-icon-sports-svg {
  max-width: 50px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  /* Allow vertical scrolling */
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  height: 100vh;
}



.mainoverlay {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 578px;
  width: 110%;
  height: 100vh;
  background-color: var(--darkBackground);
  z-index: 9999;
  display: block;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
}


.overlay-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in Vue 2 */
  {
  opacity: 0;
}


.close-overlay {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
}


#container {
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 550px;
}

h1 {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #FCFCFC;
  /* $white */
  padding-bottom: 5px;
  text-transform: uppercase;
}

.green {
  color: black;
  /* darken($secondary, 20%) */
}

.red {
  color: #D6808C;
  /* darken($error, 10%) */
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

p {
  margin-top: -5px;
  font-size: 0.5em;
  font-weight: 100;
  color: #6F6F6F;
  /* darken($dark, 10%) */
  letter-spacing: 1px;
}

button,
.dot {
  cursor: pointer;
}

#success-box {
  position: absolute;
  width: 350px;
  height: 400px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
  background: url('/app/TUCHEZEAPPDOWNLOAD.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(137, 138, 141, 0.164);
  perspective: 40px;
}


#error-box {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 12%;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 0.1);
}

.dot {
  position: absolute;
  top: 4%;
  right: 6%;
}

.close-button {
  position: absolute;
  top: -25px;
  right: -25px;
  width: 19px;
  cursor: pointer;
  z-index: 100;
  /* Ensure it stays on top */
}

.two {
  right: 12%;
  opacity: 0.5;
}

.face,
.face2 {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #FCFCFC;
  /* $white */
  border-radius: 50%;
  border: 1px solid #777777;
  /* $dark */
  top: 21%;
  left: 37.5%;
  z-index: 2;
}

.face {
  animation: bounce 1s ease-in infinite;
}

.face2 {
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  /* $dark */
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  /* $dark */
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  /* $dark */
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #777777;
  /* $dark */
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}

.move {
  animation: move 3s ease-in-out infinite;
}

.message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

.button-box {
  position: absolute;
  color: black;
  top: 83%;
  left: 30%;
  outline: 0;
  border: none;
  transition: all 0.5s ease-in-out;
}

.button-box:hover {
  background: #F5F5F5;
  /* darken($white, 5%) */
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: add a semi-transparent background */
  z-index: 9999;
  /* Set a high z-index for the overlay */
}

.card {
  width: 300px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  /* Make sure the card has a white background */
  border-radius: 5px;
  /* Optional: add some border radius for aesthetics */
  z-index: 10000;
  /* Set a higher z-index for the card */
  position: relative;
  /* Make sure z-index is applied correctly */
}

.icon-container {
  background-color: #23313D;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.icon-container-darker {
  background-color: var(--darkBackground);
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.icon-container-two {
  background-color: var(--darkBackground);
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.crash-icon-container {
  background-color: #23313D;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.header {
  padding: 0.5rem 15px;
}

.logo {
  flex-grow: 1;
  gap: 1rem
}

.top-header {
  position: sticky;
}
</style>